import { render, staticRenderFns } from "./IntegralMallInfo.vue?vue&type=template&id=04e16f62&scoped=true&"
import script from "./IntegralMallInfo.vue?vue&type=script&lang=js&"
export * from "./IntegralMallInfo.vue?vue&type=script&lang=js&"
import style0 from "./IntegralMallInfo.vue?vue&type=style&index=0&id=04e16f62&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e16f62",
  null
  
)

/* custom blocks */
import block0 from "./IntegralMallInfo.vue?vue&type=custom&index=0&blockType=span&class=infoKeyMsg&v-if=goodsinfo.type%3D%3D%3D1%20%26%26%20(Number(goodsinfo.stock)%3E0%20%26%26%20Number(goodsinfo.stock-goodsinfo.sale)%3C1)"
if (typeof block0 === 'function') block0(component)
import block1 from "./IntegralMallInfo.vue?vue&type=custom&index=1&blockType=span&class=infoKeyMsg&v-if=goodsinfo.type%3D%3D%3D1%20%26%26%20(Number(goodsinfo.stock)%3C0%20%7C%7C%20Number(goodsinfo.stock-goodsinfo.sale)%3C1)"
if (typeof block1 === 'function') block1(component)

export default component.exports