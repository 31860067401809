<template>
  <div>
    <el-row> 
      <el-col :span="24" class="infoTit">
       <span class="brandName">{{goodsinfo.brandName}} </span>
        <!-- {{ goodsinfo.name }} -->
         <span v-if="goodsinfo.alias==''||goodsinfo.alias==null">{{ goodsinfo.name }}  </span>
         <span v-else>{{ goodsinfo.alias }}  </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>说明:积分商城商品通过“积分”或“积分+现金”方式兑换</span>
      </el-col>
    </el-row>
    <el-row class="infoBox">
     
      <el-col :span="24" class="infoActivity">
        <span class="infoKey">活动说明:</span>
        <span class="infoTag">积分兑换商品</span>
        <!-- <span class="infoDes">{{goodsinfo.description}}</span> -->
      </el-col>
    </el-row>
    <el-row  :span="24"  class="infoParameter">
       <el-col :span="6">
        <span class="infoKey" style="text-indent: 30px;">积分：</span
        ><span class="price">
           {{ goodsinfo.points }}积分 <span  v-if="Number(goodsinfo.money)>0 &&goodsinfo.payType!== 1  ">+{{ goodsinfo.money | capitalize }}</span>
           </span>
           <!-- <del>{{subTotal}}</del> -->
          <!-- {{ goodsinfo.comboPrice==="***"?"会员可见":goodsinfo.comboPrice }} -->
        </el-col>
      <el-col :span="6" v-if="goodsinfo.type!==1">
        <span class="infoKeys" >已兑换<span class="price">{{goodsinfo.sale}}</span>个</span>
      </el-col>
      <el-col :span="6" v-else>
        <span class="infoKeys" >
          已有
          <span class="price">{{goodsinfo.sale}}</span>人兑换，
          剩余数量：
          <span class="price"  v-if="goodsinfo.stock<0">0</span>
          <span class="price"  v-else>{{goodsinfo.stock}}</span>
        </span>
      </el-col>
      <!-- type	1.实体商品，2.优惠券 -->
      <el-col :span="6" v-if="goodsinfo.type===1">
        <!-- {{goodsinfo.comboTotal-(goodsinfo.comboUsage>0?goodsinfo.comboUsage:0)}} -->
        <span class="infoKey" style="text-indent: 30px;">库存:</span>
        <!-- <span>{{goodsinfo.quantity==-1 ||goodsinfo.quantity==0 ?'备货中':goodsinfo.quantity>1000?"充足":goodsinfo.quantity+goodsinfo.unit}}</span> -->
          <!-- <span class="msg" v-if="Number(goodsinfo.stock-goodsinfo.sale)<0">0</span> -->
          <!-- <span class="msg" v-else>{{goodsinfo.stock-goodsinfo.sale}}</span> -->
          <span class="msg" >{{goodsinfo.stock}}</span>
      </el-col>
    </el-row>
   <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="stepNum"
          :max="Number(MaxNum)<0? Number(0):Number(MaxNum)"
          :step="stepNum"
          step-strictly
        ></el-input-number>
      </el-col>
    </el-row>

    <div class="infoBtns">
      <div :span="24">
        <span class="infoKey"></span>
        <button :disabled="MaxNum< 1 " 
                :class="MaxNum < 1 || !goodsinfo.businessFlag?'grey addBtn':'addBtn'" 
                 @click="immediateBuy()" v-preventReClick>
          立即兑换
        </button>
        <span class="infoKeyMsg" v-if="Number(PointsNum/goodsinfo.points)<1">(积分不足)</span>
        <span class="infoKeyMsg" v-if="goodsinfo.type===1 && (Number(goodsinfo.stock)<0)">(库存不足)</span>
        <span class="infoKeyMsg" v-if="!goodsinfo.businessFlag">(经营范围不包含{{goodsinfo.businessTypeName}})</span>
      </div>
    </div>
  </div>
</template>
// <span class="infoKeyMsg" v-if="goodsinfo.type===1 && (Number(goodsinfo.stock)>0 && Number(goodsinfo.stock-goodsinfo.sale)<1)">(库存不足)</span>
// <span class="infoKeyMsg" v-if="goodsinfo.type===1 && (Number(goodsinfo.stock)<0 || Number(goodsinfo.stock-goodsinfo.sale)<1)">(库存不足)</span> 
<style lang="less" scoped>
@import "~style/index.less";
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .brandName{
      font-weight: bolder;
      color: #333;
    }
    .tag{
        width:42px;
        text-align: center;
          height: 20px;
          // padding: 0 5px;
          line-height: 19px;
          margin-top: 2px;
          border-width: 1px;
          font-size:14px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
        margin-right:3px;
          &.titColor{
            color:#fff
          }
           &.radiusBox{
            border-radius:50%;
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
          &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
  }
  .infoMsg {
    color: #FFD303;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
        margin-top: 5px;
  }
  span.infoKey {
    width: 86px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  span.infoKeys {
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  .downBtn{
    cursor: pointer;
    &:hover{
         color: #FFD303;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 9999;
    position: relative;
    margin-bottom: 20px;
    .infoKeyMsg{
      color: #FFD303;
      padding-left: 20px;
      font-size: 13px;
    }
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: #FFD303;
        color: #000;
        margin-left: 20px;
        border: 1px solid #FFD303;
        outline: none;
      }
      &.shopBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.grey{
         background: #d4d2cf;
          color: #333;
          border: 1px solid #d4d2cf;
      }
    }
  }
  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 20px;
    color: #333;
    
    del{
      color: #ccc;
      font-size: 13px;
    }
    span.price {
      color: #f43c38;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.msg{
        color: #666;
        font-size: 13px;
        padding-left:10px;
      }
    div{
      float: left;
      width: 50%;
      
       margin-bottom: 15px;
        &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // .el-col {
    //   margin-bottom: 15px;
     
    // }
  }
  .infoBox {
    background: #f6f6f6;
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top:10px;
    .el-col {
      margin-bottom: 25px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom:25px;
      }
    }

    
    span.infoTag {
      border: 1px solid #FFD303;
      color: #FFD303;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0 10px;
    }
    span.infoDes {
      color: #666;
    }
  }
}
</style>
<script>
import {userPointsNum } from 'api/member.js';
import {CreateOrder}  from "api/product.js"
export default {
  name: "IntegralMallInfo",
  data() {
    return {
      MaxNum:'',
       PointsNum: 0,
      stepNum:1,
      num:1,
      promotionMap:[],
      subTitle:'',
      // subTotal:0
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
  },
  components: {},

  methods: {
  
    handleChange() {
      // value
    },
  // 立即兌換
    immediateBuy:function() {
          if(this.goodsinfo.points==="***"){
              this.$message({
                  message:'未认证用户不能购买',
                  type:"warning"
              })
              return false
          }

          let datas={
            goodsCount: this.num,
            pointsRedeemProductId:this.goodsinfo.id,
          }
          sessionStorage.removeItem('billQRCode')
          // 生成积分商品兑换订单
          CreateOrder(datas).then(res=>{
            if(res.data.code===200){
                 // 单独成单，跳订单结算,query:{ids:moreIds}}
              this.$router.push({ name: "IntegralMallAmount",query:{id:res.data.data.id}});
            }else{
              this.$message({
                  message:res.data.msg,
                  type:"warning"
              })
            }
             
          })
        
    }
  },
  mounted() {
  },
  watch: {
    goodsinfo: function() {
    },
  },
  created() {
     // 获取积分数量  和积分人员编号
      userPointsNum().then(res=>{
        this.PointsNum=res.data.data.points
        if(this.goodsinfo.type!==1){
          this.MaxNum=10000
        }else{
          this.MaxNum=parseInt(Number(this.PointsNum/this.goodsinfo.points))>Number(this.goodsinfo.stock)?Number(this.goodsinfo.stock):Number(this.PointsNum/this.goodsinfo.points)
        
        // alert(this.PointsNum   +'jifen')
        // alert(this.goodsinfo.points+'兑换需要积分')
        }
      })
    this.num=1
    this.promotionMap=this.goodsinfo.promotionMap
  
  },
};
</script>
